import React, { useState } from "react";
import { Flex, Form, Input, Button, Alert, Spin } from "antd";
import useLogin from "../hooks/useLogin";

const Login = () => {
  const { error, loading, loginUser } = useLogin();

  const handleLogin = async (value) => {
    await loginUser(value);
  };

  return (
    <section className="bg-white">
      <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
        <aside className="relative block h-16 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6">
          <img
            alt=""
            src="https://images.unsplash.com/photo-1605106702734-205df224ecce?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
            className="absolute inset-0 h-full w-full object-cover"
          />
        </aside>

        <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
          <div className="max-w-xl lg:max-w-3xl">
            <h1 className="mt-6 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
              Decent Care Portal
            </h1>

            <p className="mt-4 leading-relaxed text-gray-500">
              If you do not have a access to the portal, contact your manager or
              supervisor to provide you with the access credentials.
            </p>
            {/* Login Form */}

            <Flex>
              <Form
                layout="vertical"
                onFinish={handleLogin}
                autoComplete="off"
                autoSave="off"
                className="w-full"
              >
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please provide email" },
                    { type: "email", message: "It is not a Valid email" },
                  ]}
                >
                  <Input
                    placeholder="Enter your email"
                    size="large"
                    className="rounded-md border-slate-300"
                  />
                </Form.Item>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: "Please  password" }]}
                >
                  <Input.Password
                    placeholder="Enter your password"
                    size="large"
                  />
                </Form.Item>
                {error && (
                  <Alert
                    description={error}
                    type="error"
                    showIcon
                    closable
                    className="alert"
                  />
                )}
                <Form.Item>
                  <Button
                    type={`${loading ? "" : "primary"}`}
                    htmlType="submit"
                    size="large"
                    className="w-full bg-blue-700"
                  >
                    {loading ? <Spin /> : "Sign In"}
                  </Button>
                </Form.Item>
              </Form>
            </Flex>
          </div>
        </main>
      </div>
    </section>
  );
};

export default Login;

{
  /* <form onSubmit={handleLogin} className="mt-8 grid grid-cols-6 gap-6">
  <div className="col-span-6">
    <label htmlFor="Email" className="block text-sm font-medium text-gray-700">
      Email
    </label>

    <input
      type="email"
      id="Email"
      name="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm"
    />
  </div>
  <div className="col-span-6">
    <label
      htmlFor="Password"
      className="block text-sm font-medium text-gray-700"
    >
      Password
    </label>
    <div className="relative">
      <input
        type="password"
        id="Password"
        name="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        className="mt-1 w-full rounded-md border-gray-200 bg-white text-sm text-gray-700 shadow-sm pr-10"
      />
    </div>
  </div>

  <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
    <button className="inline-block shrink-0 rounded-md border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-blue-600 focus:outline-none focus:ring active:text-blue-500">
      Log In
    </button>
  </div>
</form>; */
}
