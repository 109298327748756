import React, { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { IoExtensionPuzzleOutline } from "react-icons/io5";
import api from "../../../api/api";
import PopUpError from "../../../widgets/error/PopUpError";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import BreadCrumb from "../../breadCrumb/BreadCrumb";
import Input from "../formElements/inputs/Input";
import SelectInput from "../formElements/slectInput/SelectInput";
import Modal from "../../modal/Modal";
import SubmitButton from "../formElements/submitButton/SubmitButton";
import LoadingButton from "../formElements/loadingButton/LoadingButton";

const AddDsMember = ({ members, setMembers }) => {
  let [isOpen, setIsOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [ndis, setNdis] = useState("");
  const [community, setCommunity] = useState("");
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newMember = { name, ndis, community };

    try {
      setLoading(true);
      const response = await api.post("/members", newMember);
      const allMembers = [...members, response.data];
      setMembers(allMembers);
      if (response.data) {
        toast.success("User Added Successfully", {
          position: "bottom-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setIsOpen(true);
      }
      setName("");
      setNdis("");
      setCommunity("");
    } catch (error) {
      console.log(error);
      setError(true);
      if (error.response) {
        setErrorMessage(error.response);
      } else {
        setErrorMessage({ data: { message: "An error occurred" } });
      }
    } finally {
      setLoading(false);
    }
  };

  const navigation = () => {
    navigate("/members");
  };

  return (
    <>
      <BreadCrumb
        currentPage="Add Participant"
        prevPage="Members"
        icon={<IoExtensionPuzzleOutline />}
        navigation={navigation}
      />
      <div className="mt-2">
        <ToastContainer />
        {error && errorMessage.data && (
          <PopUpError message={errorMessage.data.message} />
        )}

        <div className="w-full shadow-md rounded-md">
          <form action="" onSubmit={handleSubmit}>
            <div className="w-full h-14 bg-slate-200">
              <h3 className="text-xl p-4 font-bold text-slate-600">User</h3>
            </div>
            <div className="flex flex-col items-center">
              {/* Name */}
              <Input
                id="name"
                value={name}
                change={setName}
                placeholder="Full Name"
                type="text"
              />
              {/* NDIS */}
              <Input
                id="ndis"
                value={ndis}
                change={setNdis}
                placeholder="NDIS Number"
                type="text"
              />
              <SelectInput
                value={community}
                change={setCommunity}
                id="community"
                placeholder="Community"
                options={[
                  { value: "A", name: "A" },
                  { value: "B", name: "B" },
                  { value: "C", name: "C" },
                  { value: "D", name: "D" },
                ]}
              />
              {loading ? (
                <LoadingButton />
              ) : (
                <SubmitButton
                  btnName="Add Member"
                  icon={<AiOutlineArrowRight />}
                />
              )}
            </div>
          </form>
        </div>
      </div>
      {/* Modal */}
      <Modal
        btnName="Add Another User"
        title="Member Added 🎉"
        message="Member added to the system successfully. Would you like to add another member?"
        navigation={navigation}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default AddDsMember;
