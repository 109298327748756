import React from "react";
import { GrUserAdd } from "react-icons/gr";

const ButtonGroup = ({ navigation }) => {
  return (
    <span className="inline-flex overflow-hidden rounded-md border bg-white shadow-sm">
      <button
        onClick={navigation}
        className="inline-block border-e px-4 py-2 text-sm font-medium text-gray-700 hover:bg-indigo-600 hover:text-white focus:relative"
      >
        Add Participant
      </button>

      <button
        className="inline-block px-4 py-2 text-gray-700 hover:bg-gray-50 focus:relative"
        title="View Orders"
        disabled
      >
        <GrUserAdd />
      </button>
    </span>
  );
};

export default ButtonGroup;
