import React from "react";
import { IoMdCloseCircleOutline } from "react-icons/io";

const Beta = ({ close }) => {
  return (
    <div className="w-[40%] mx-auto text-center py-1 lg:px-4">
      <div
        className="p-2 bg-indigo-800 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex"
        role="alert"
      >
        <span className="flex rounded-full bg-indigo-500 uppercase px-2 py-1 text-xs font-bold mr-3">
          Beta
        </span>
        <span className="font-semibold mr-2 text-left flex-auto">
          Current Version : Beta
        </span>
        <IoMdCloseCircleOutline role="button" onClick={() => close(false)} />
      </div>
    </div>
  );
};

export default Beta;
