import React from "react";
import StatCard from "../../components/card/StatCard/StatCard";
import { calculateTotalFunding } from "../../utils/calculateTotalFunding";
import { calculateSpending } from "../../utils/fundingStatus";
import api from "../../api/api";
// icons
import { BsCurrencyDollar } from "react-icons/bs";
import { LiaFileInvoiceDollarSolid } from "react-icons/lia";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdKeyboardArrowUp } from "react-icons/md";
import { FaClockRotateLeft } from "react-icons/fa6";

import { useAuth } from "../../context/AuthContext";

const CardSection = ({ planStart, planEnd, fundingTypes, singleMember }) => {
  const { userData } = useAuth();

  const coreFundsTotal = calculateTotalFunding(fundingTypes, "core budget");
  const spending = calculateSpending(
    planStart,
    planEnd,
    coreFundsTotal.totalBudget,
    coreFundsTotal.totalRemaining
  );

  return (
    <div className="grid grid-cols-1 gap-4 mt-5 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
      <StatCard
        Icon={userData.position !== "tl" ? BsCurrencyDollar : FaClockRotateLeft}
        funds={coreFundsTotal.totalBudget}
        title={
          userData.position !== "tl" ? "Total Core Funds" : "Total Core Hours"
        }
      />
      <StatCard
        Icon={
          userData.position !== "tl"
            ? LiaFileInvoiceDollarSolid
            : FaClockRotateLeft
        }
        funds={coreFundsTotal.totalRemaining}
        title={
          userData.position !== "tl"
            ? "Total Funds Remaining"
            : "Total Hours Remaining"
        }
      />
      <StatCard
        Icon={
          spending.overspending !== 0 ? MdKeyboardArrowUp : MdKeyboardArrowDown
        }
        funds={
          spending.overspending !== 0
            ? parseFloat(spending.overspending.toFixed(2))
            : parseFloat(spending.underspending.toFixed(2))
        }
        title={
          spending.overspending !== 0
            ? userData.position !== "tl"
              ? "Over Spent | year to date"
              : "Hours Over | year to date"
            : userData.position !== "tl"
            ? "Under Spent | year to date"
            : "Hours under | year to date"
        }
        color={spending.overspending !== 0 ? "#F1948A" : "#5DADE2"}
        textColor={spending.overspending !== 0 ? "#F1948A" : "#5DADE2"}
      />
      <StatCard
        Icon={
          spending.overspendingPerMonth !== 0
            ? MdKeyboardArrowUp
            : MdKeyboardArrowDown
        }
        funds={
          spending.overspendingPerMonth !== 0
            ? parseFloat(spending.overspendingPerMonth.toFixed(2))
            : parseFloat(spending.underspendingPerMonth.toFixed(2))
        }
        title={
          spending.overspendingPerMonth !== 0
            ? "Over Spending Per Month Avg"
            : "Under Spending Per Month Avg"
        }
        color={spending.overspendingPerMonth !== 0 ? "#F1948A" : "#5DADE2"}
        textColor={spending.overspendingPerMonth !== 0 ? "#F1948A" : "#5DADE2"}
      />
    </div>
  );
};

export default CardSection;
