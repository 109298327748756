import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api/api";
import Projection from "../../components/projection/Projection";
import DefaultLayout from "../../layout/DefaultLayout";
import MemberInfoCard from "../../components/card/memberInfoCard/MemberInfoCard";
import CardSection from "./CardSection";
import PieChartFunding from "../../components/charts/PieChartFunding";
import Loading from "../../widgets/loading/Loading";
import InformationAlert from "../../widgets/alert/InformationAlert";
import { useAuth } from "../../context/AuthContext";

const MemberPage = ({ members, setMembers }) => {
  const { id } = useParams();
  const [member, setMember] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();
  const singleMember = members.find((member) => member._id === id);
  const { userData } = useAuth();

  useEffect(() => {
    const fetchMember = async () => {
      try {
        const response = await api.get(`/members/${id}`);
        setMember(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          console.log(`Error: ${error.message}`);
        }

        setError(error.response.data);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMember();
  }, [id]);

  if (!isLoading) {
    var coreBudgets = member.planBudgets.filter(
      (budget) => budget.group.toLowerCase() === "core budget"
    );

    var budgetArray = coreBudgets.map((budget) => ({
      planStartDate: member.ndisPlanStartDate,
      planEndDate: member.ndisPlanEndDate,
      ...budget,
    }));
  }

  const handleDelete = async (id) => {
    try {
      const response = await api.delete(`/members`, { data: { id: id } });
      console.log(response.data);
      const allMembers = members.filter((member) => member._id !== id);
      setMembers(allMembers);
      navigate("/");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <DefaultLayout>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {userData && userData.position === "tl" && open && (
            <InformationAlert open={setOpen} />
          )}
          <h1 className="text-3xl text-slate-500 font-bold">
            {singleMember.name}
          </h1>
          <CardSection
            planStart={member.ndisPlanStartDate}
            planEnd={member.ndisPlanEndDate}
            fundingTypes={member.planBudgets}
            singleMember={singleMember}
          />
          <div className="grid grid-cols-2 gap-2 mt-10">
            <MemberInfoCard
              memberInfo={singleMember}
              planStart={member.ndisPlanStartDate}
              planEnd={member.ndisPlanEndDate}
            />
            <PieChartFunding loading={isLoading} planBudgets={member} />
          </div>

          <div className="grid grid-cols-1 gap-4 mt-5">
            {budgetArray ? (
              budgetArray.map((budget) => (
                <Projection
                  key={budget.categoryId}
                  planStart={budget.planStartDate}
                  planEnd={budget.planEndDate}
                  category={budget.category}
                  budget={budget.budget}
                  remaining={budget.remaining}
                />
              ))
            ) : (
              <p>No Data in CareView</p>
            )}
          </div>
        </>
      )}
    </DefaultLayout>
  );
};

export default MemberPage;
