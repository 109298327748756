import React from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import { useNavigate } from "react-router-dom";
import DsMember from "../../components/table/DsMember";
import ButtonGroup from "../../components/buttonGroup/ButtonGroup";
import { useAuth } from "../../context/AuthContext";

const MembersList = ({ members }) => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/members/add");
  };

  const userData = useAuth();
  return (
    <DefaultLayout>
      {userData.userData.position !== "tl" && (
        <div className=" mb-2">
          <ButtonGroup navigation={handleNavigation} />
        </div>
      )}

      {members.length ? (
        <DsMember members={members} />
      ) : (
        <p style={{ marginTop: "2rem" }}>There are no members in the system</p>
      )}
    </DefaultLayout>
  );
};

export default MembersList;

// <MemberTable members = {members}/>
