import React, { useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useNavigate } from "react-router-dom";
import TableSearch from "./TableSearch";
import { IoEyeOutline } from "react-icons/io5";
import { LiaGreaterThanSolid } from "react-icons/lia";
import { LiaLessThanSolid } from "react-icons/lia";
import { CiSearch } from "react-icons/ci";

const DsMember = ({ members }) => {
  const columnHelper = createColumnHelper();
  const navigate = useNavigate();
  const data = members;

  const columns = [
    columnHelper.accessor("", {
      id: "S.No",
      cell: (info) => <span>{info.row.index + 1}</span>,
      header: "S.No",
    }),

    columnHelper.accessor("name", {
      cell: (info) => <span>{info.getValue()}</span>,
      header: "Participant Name",
    }),
    columnHelper.accessor("ndis", {
      cell: (info) => <span>{info.getValue()}</span>,
      header: "NDIS",
    }),
    columnHelper.accessor("community", {
      cell: (info) => (
        <span
          className={`rounded-md bg-opacity-10 py-1 px-3 text-sm font-medium w-fit ${
            info.getValue() === "A"
              ? "bg-green-400 text-green-600"
              : info.getValue() === "B"
              ? "bg-cyan-400 text-cyan-600"
              : info.getValue() === "C"
              ? "bg-violet-400 text-violet-600"
              : "bg-pink-400 text-pink-600"
          }`}
        >
          {info.getValue()}
        </span>
      ),
      header: "Community",
    }),
    columnHelper.accessor("visits", {
      cell: (info) => (
        <div className="flex items-center space-x-3.5">
          <IoEyeOutline
            role="button"
            onClick={() => navigate(`/members/${info.row.original._id}`)}
          />
        </div>
      ),
      header: "View",
    }),
  ];
  const [globalFilter, setGlobalFilter] = useState("");
  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
    },
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  return (
    <div className="p-2 max-w-[100%] mx-auto text-slate-600 fill-gray-400 border rounded-lg shadow-md">
      <div className="flex justify-between mb-2">
        <div className="w-full flex items-center gap-1 ">
          {/* //*/}
          <CiSearch />
          <TableSearch
            value={globalFilter ?? ""}
            onChange={(value) => setGlobalFilter(String(value))}
            className="p-2 bg-transparent outline-none border-none w-1/5 focus:w-1/3 duration-300 "
            placeholder="Search all columns..."
          />
        </div>
      </div>
      <table className="border  rounded-lg w-full text-left text-slate-600">
        <thead className="bg-slate-200 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id} className="capitalize px-3.5 py-2">
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length ? (
            table.getRowModel().rows.map((row, i) => (
              <tr
                key={row.id}
                className={`
                ${i % 2 === 0 ? "bg-white" : "bg-slate-50"}
                `}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="px-3.5 py-2">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))
          ) : (
            <tr className="text-center h-32">
              <td colSpan={12}>No Recoard Found!</td>
            </tr>
          )}
        </tbody>
      </table>
      {/* pagination */}
      <div className="flex items-center justify-end mt-2 gap-2">
        <button
          onClick={() => {
            table.previousPage();
          }}
          disabled={!table.getCanPreviousPage()}
          className="p-1 text-indigo-700 border border-gray-100 px-2 rounded-md"
        >
          <LiaLessThanSolid size={20} />
        </button>
        <span className="flex text-slate-600 items-center gap-1">
          <p>Page</p>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </span>
        <button
          onClick={() => {
            table.nextPage();
          }}
          disabled={!table.getCanNextPage()}
          className="p-1 text-indigo-700 border border-gray-100 px-2 rounded-md "
        >
          <LiaGreaterThanSolid size={20} />
        </button>

        <span className="flex items-center gap-1 text-slate-600">
          Go to page:
          <input
            type="number"
            defaultValue={table.getState().pagination.pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              table.setPageIndex(page);
            }}
            className="border p-1 rounded w-12 h-8 border-slate-100 "
          />
        </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
          className="border p-1 rounded w-32 h-8 border-slate-100 text-slate-600"
        >
          {[10, 20, 30, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DsMember;
