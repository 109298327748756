import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";

const BarChartDs = ({ summaryList }) => {
  const dateList = summaryList.length
    ? summaryList.map((summary) => {
        const date = new Date(summary.weekEnding);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // JavaScript months are 0-indexed
        const year = String(date.getFullYear()).substr(-2);
        return `${day}/${month}/${year}`;
      })
    : [];

  const totalHours = summaryList.length
    ? summaryList.map((summary) => Number(summary.totalHours))
    : [];

  return (
    <BarChart
      series={[{ data: totalHours, label: "Department Service Hours" }]}
      height={350}
      xAxis={[{ data: dateList, scaleType: "band" }]}
    />
  );
};

export default BarChartDs;
