import React from "react";

const SalesCard = ({ cardName, setSelectedBtn }) => {
  const details = {
    name:
      cardName === "ds"
        ? "Direct Supports"
        : cardName === "sc"
        ? "Support Coordination"
        : cardName === "pm"
        ? "Plan Management"
        : "Company",
    salesBtn:
      cardName === "ds"
        ? "dsSales"
        : cardName === "sc"
        ? "scSales"
        : cardName === "pm"
        ? "pmSales"
        : "companySales",
    expenseBtn:
      cardName === "ds"
        ? "dsExpense"
        : cardName === "sc"
        ? "scExpense"
        : cardName === "pm"
        ? "pmExpense"
        : "companyExpense",
    viewBtn:
      cardName === "ds"
        ? "dsView"
        : cardName === "sc"
        ? "scView"
        : cardName === "pm"
        ? "pmView"
        : "companyView",
    color:
      cardName === "ds"
        ? "#EBDEF0"
        : cardName === "sc"
        ? "#D4E6F1 "
        : cardName === "pm"
        ? "#D5F5E3  "
        : "#FAE5D3 ",
  };

  return (
    <div className="w-[90%] h-40  mx-auto rounded-md bg-white shadow-md mb-5">
      <div
        className="flex items-center w-full h-1/4 rounded-t-md"
        style={{ backgroundColor: details.color }}
      >
        <h3 className="text-lg font-bold text-slate-700 ml-3">
          {details.name}
        </h3>
      </div>
      <div className="flex justify-between items-center w-[98%] mx-auto h-1/4 border-b">
        <span className="text-md text-slate-700 ml-3"> Weekly Sales</span>
        <button
          onClick={() => setSelectedBtn(details.salesBtn)}
          className="group relative inline-block mr-3 overflow-hidden border border-indigo-600 w-40 rounded-md focus:outline-none focus:ring"
        >
          <span className="absolute inset-y-0 left-0 w-[2px] bg-indigo-300 transition-all group-hover:w-full group-active:bg-indigo-200" />
          <span className="relative text-sm font-lg font-extrabold text-slate-700 transition-colors group-hover:text-indigo-800 ">
            Add Sales
          </span>
        </button>
      </div>
      <div className="flex justify-between items-center w-[98%] mx-auto h-1/4 border-b">
        <span className="text-md text-slate-700 ml-3"> Weekly Expense</span>
        <button
          onClick={() => setSelectedBtn(details.expenseBtn)}
          className="group relative inline-block mr-3 overflow-hidden border border-indigo-600 w-40 rounded-md focus:outline-none focus:ring"
        >
          <span className="absolute inset-y-0 left-0 w-[2px] bg-indigo-300 transition-all group-hover:w-full group-active:bg-indigo-200" />
          <span className="relative text-sm font-lg font-extrabold text-slate-700 transition-colors group-hover:text-indigo-800 ">
            Add Expense
          </span>
        </button>
      </div>
      <div className="flex justify-between items-center w-[98%] mx-auto h-1/4 ">
        <span className="text-md text-slate-700 ml-3"> Weekly Summary</span>
        <button
          onClick={() => setSelectedBtn(details.viewBtn)}
          className="group relative inline-block mr-3 overflow-hidden border border-indigo-600 w-40 rounded-md focus:outline-none focus:ring"
        >
          <span className="absolute inset-y-0 left-0 w-[2px] bg-indigo-300 transition-all group-hover:w-full group-active:bg-indigo-200" />
          <span className="relative text-sm font-lg font-extrabold text-slate-700 transition-colors group-hover:text-indigo-800 ">
            Add Summary
          </span>
        </button>
      </div>
    </div>
  );
};

export default SalesCard;
