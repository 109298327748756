import React from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import AddDsMember from "../../components/forms/addDsMember/AddDsMember";

const NewMember = ({ members, setMembers }) => {
  console.log(typeof setMembers);
  return (
    <DefaultLayout>
      <AddDsMember members={members} setMembers={setMembers} />
    </DefaultLayout>
  );
};

export default NewMember;
