import DefaultLayout from "../../layout/DefaultLayout";
import DirectSupports from "./DirectSupports";
import Beta from "../../widgets/alert/Beta";
import { useState } from "react";
const DashBoard = () => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <DefaultLayout>
      {isOpen && <Beta close={setIsOpen} />}
      <DirectSupports />
    </DefaultLayout>
  );
};

export default DashBoard;
