import React from "react";
import Datepicker from "react-tailwindcss-datepicker";

const DateRange = ({
  handleValueChange,
  value,
  numOfCommunity,
  setNumOfCommunity,
}) => {
  return (
    <form action="">
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Community Sales
          </h2>
          <p className="mt-1 mb-7 text-sm leading-6 text-gray-600">
            This data will be added to the Decent Care Database, ensure all the
            data is correct and accurate.
          </p>
          <Datepicker
            separator={" to "}
            placeholder={"Week Start to Week End"}
            value={value}
            onChange={handleValueChange}
            showShortcuts={true}
            inputClassName="w-full rounded-md border-gray-300 focus:ring-0 font-normal bg-green-100 dark:bg-white text-gray-900"
          />
          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-4">
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Number of Community
              </label>
              <div className="mt-2">
                <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                  <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                    Communities/
                  </span>
                  <input
                    type="number"
                    name="username"
                    id="username"
                    className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                    value={numOfCommunity}
                  />
                </div>
              </div>
            </div>
          </div>
          {/* Community End */}
          {/* Buttons */}
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="submit"
              className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default DateRange;
