import React, { useState } from "react";
import { AiOutlineArrowRight } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import api from "../../../api/api";
import PopUpError from "../../../widgets/error/PopUpError";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../formElements/inputs/Input";
import SelectInput from "../formElements/slectInput/SelectInput";
import SubmitButton from "../formElements/submitButton/SubmitButton";
import BreadCrumb from "../../breadCrumb/BreadCrumb";
import Modal from "../../modal/Modal";
import LoadingButton from "../formElements/loadingButton/LoadingButton";

const AddUser = ({ setSelectedComponent }) => {
  let [isOpen, setIsOpen] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [department, setDepartment] = useState("");
  const [position, setPosition] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [role, setRole] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user = {
      name: name,
      email: email,
      password: password,
      department: department,
      position: position,
      role: role,
    };
    try {
      setLoading(true);
      const response = await api.post("/user", user);
      if (response) {
        toast.success("User Added Successfully", {
          position: "bottom-left",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
        setIsOpen(true);
      }
      setName("");
      setEmail("");
      setPassword("");
      setPosition("");
      setDepartment("");
      setRole("");
    } catch (error) {
      setError(error);
      setErrorMessage(error.response.data);
    } finally {
      setLoading(false);
    }
  };

  function navigation() {
    setSelectedComponent(null);
  }

  return (
    <>
      <BreadCrumb
        currentPage="Add Users"
        prevPage="Settings"
        icon={<FiSettings />}
        navigation={navigation}
      />

      <div className="mt-2">
        <ToastContainer />
        {error && <PopUpError message={errorMessage.message} />}
        <div className="w-full shadow-md rounded-md">
          <form action="" onSubmit={handleSubmit}>
            <div className="w-full h-14 bg-slate-200">
              <h3 className="text-xl p-4 font-bold text-slate-600">User</h3>
            </div>
            <div className="flex flex-col items-center">
              <Input
                id="name"
                value={name}
                change={setName}
                type="text"
                placeholder="Full Name"
              />

              {/* Email */}
              <Input
                id="email"
                value={email}
                change={setEmail}
                type="email"
                placeholder="Email"
              />

              {/* Password */}
              <Input
                id="password"
                value={password}
                change={setPassword}
                type="text"
                placeholder="User Password"
              />

              {/* Department */}
              <SelectInput
                value={department}
                change={setDepartment}
                id="department"
                placeholder="Department"
                options={[
                  { value: "directSupport", name: "Direct Supports" },
                  { value: "serviceDelivery", name: "Service Delivery" },
                  { value: "finance", name: "Finance Department" },
                  { value: "tle", name: "Top Level Executive" },
                ]}
              />

              {/* Position */}
              <SelectInput
                value={position}
                change={setPosition}
                id="position"
                placeholder="Position"
                options={[
                  { value: "tl", name: "Team Leader" },
                  { value: "dsPm", name: "DS Program Manager" },
                  { value: "sdm", name: "Service Delivery Manager" },
                  { value: "ceo", name: "CEO" },
                  { value: "cfo", name: "CFO" },
                  { value: "opsManager", name: "Operations Manager" },
                ]}
              />

              {/* User Privilege */}
              <SelectInput
                value={role}
                change={setRole}
                id="role"
                placeholder="Permission Level"
                options={[
                  { value: "user", name: "User" },
                  { value: "admin", name: "Admin" },
                ]}
              />

              {loading ? (
                <LoadingButton />
              ) : (
                <SubmitButton
                  btnName="Add Member"
                  icon={<AiOutlineArrowRight />}
                />
              )}
            </div>
          </form>
        </div>
      </div>
      {/* Modal */}
      <Modal
        btnName="Add Another User"
        title="User Added Successfully 🎉"
        message="📧 Email is on way to inform that the user has been invited to Decent Care Portal. Would you like to add another user?"
        navigation={navigation}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};

export default AddUser;
