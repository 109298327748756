import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import DashBoard from "./Pages/dashboard/DashBoard";
import MembersList from "./Pages/members/MembersList";
import NewMember from "./Pages/members/NewMember";
import MemberPage from "./Pages/members/MemberPage";
import Accounts from "./Pages/accounts/Accounts";
import Login from "./auth/Login";
import Settings from "./Pages/settings/Settings";
import Construction from "./components/Construction/Construction";
import api from "./api/api";

import { useAuth } from "./context/AuthContext";

function App() {
  const [members, setMembers] = useState([]);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        const response = await api.get("/members");
        setMembers(response.data);
      } catch (error) {
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else {
          console.log(`Error: ${error.message}`);
        }
      }
    };

    fetchMembers();
  }, []);

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 1000);

    return () => clearTimeout(timer); // This will clear the timeout if the component is unmounted before the timeout finishes
  }, []);

  const { isAuthenticated, userData } = useAuth();

  return (
    <div className="App">
      <Routes>
        {!isAuthenticated && (
          <Route
            element={!isAuthenticated ? <Login /> : <Navigate to="/" />}
            path="/login"
          />
        )}

        <Route path="/" element={isAuthenticated ? <DashBoard /> : <Login />} />

        <Route
          path="/members"
          element={
            isAuthenticated ? <MembersList members={members} /> : <Login />
          }
        />
        <Route
          path="/members/add"
          element={
            isAuthenticated ? (
              <NewMember members={members} setMembers={setMembers} />
            ) : (
              <Login />
            )
          }
        />
        <Route
          path="/members/:id"
          element={
            isAuthenticated ? (
              <MemberPage members={members} setMembers={setMembers} />
            ) : (
              <Login />
            )
          }
        />
        {isLoaded && userData && userData.position !== "tl" && (
          <>
            <Route
              path="/accounts"
              element={isAuthenticated ? <Accounts /> : <Login />}
            />
            <Route
              path="/settings"
              element={isAuthenticated ? <Settings /> : <Login />}
            />
          </>
        )}
        <Route path="*" exact={true} element={<Construction />} />
      </Routes>
    </div>
  );
}

export default App;
