export const calculateSpending = (planStartDateStr, planEndDateStr, totalFunding, totalRemaining) => {
    // Convert date strings to Date objects
    const planStartDate = new Date(planStartDateStr);
    const planEndDate = new Date(planEndDateStr);

    // Calculate the total duration of the plan in days
    const totalDuration = (planEndDate - planStartDate) / (1000 * 3600 * 24);
    
    // Calculate the current date
    const currentDate = new Date();
    
    // Calculate the elapsed duration of the plan in days
    const elapsedDuration = (currentDate - planStartDate) / (1000 * 3600 * 24);
    
    // Calculate the remaining duration of the plan in days
    const remainingDuration = (planEndDate - currentDate) / (1000 * 3600 * 24);
    
    // Calculate the percentage of time passed
    const timePassedPercentage = (elapsedDuration / totalDuration) * 100;
    
    // Calculate the total amount the participant should have spent by now
    const totalShouldHaveSpent = totalFunding * (timePassedPercentage / 100);
    
    // Calculate the total amount the participant actually spent
    const totalSpent = totalFunding - totalRemaining;
    
    // Calculate the percentage of total spent
    const totalSpentPercentage = (totalSpent / totalFunding) * 100;
    
    // Calculate the percentage of total should have spent
    const totalShouldHaveSpentPercentage = (totalShouldHaveSpent / totalFunding) * 100;
    
    // Calculate overspending or underspending
    let overspending = 0;
    let underspending = 0;
    let overspendingPerMonth = 0;
    let underspendingPerMonth = 0;
    if (totalSpent > totalShouldHaveSpent) {
        overspending = totalSpent - totalShouldHaveSpent;
        overspendingPerMonth = overspending / (elapsedDuration / 30); // Convert days to months
    } else {
        underspending = totalShouldHaveSpent - totalSpent;
        underspendingPerMonth = underspending / (elapsedDuration / 30); // Convert days to months
    }
    
    return {
        timePassedPercentage,
        totalShouldHaveSpent,
        totalSpent,
        totalSpentPercentage,
        totalShouldHaveSpentPercentage,
        overspending,
        underspending,
        overspendingPerMonth,
        underspendingPerMonth
    };
}
