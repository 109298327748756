
export const calculateTotalFunding = (funding, type) => {
  
    var budgets = funding.filter((budget)=> budget.group.toLowerCase()===type);
      
    let totalBudget = 0;
    let totalRemaining = 0;
  
    for (let item of budgets) {
        totalBudget += item.budget;
        totalRemaining += item.remaining;
    }
  
    let totalUsed = totalBudget - totalRemaining;
  
    return {
        totalBudget,
        totalRemaining,
        totalUsed
    };
}