import React, { useState } from "react"
import ReactApexChart from "react-apexcharts"

const options = {
  chart: {
    fontFamily: "Satoshi, sans-serif",
    type: "donut"
  },
  colors: ["#45B39D", "#6577F3", "#8FD0EF"],
  labels: ["Consumables", "Social & Community Participation", "Assistance With Daily Life", ],
  legend: {
    show: true,
    position: "bottom"
  },

  plotOptions: {
    pie: {
      donut: {
        size: "65%",
        background: "transparent"
      }
    }
  },
  dataLabels: {
    enabled: true
  },
  responsive: [
    {
      breakpoint: 2600,
      options: {
        chart: {
          width: 380
        }
      }
    },
    {
      breakpoint: 640,
      options: {
        chart: {
          width: 200
        }
      }
    }
  ]
}

//0=Consumables
//1=social and community participation
//2=Assistance with daily life

const budgetArray = (obj) => {


    let totalBudget = 0;
    for (let item of obj) {
        if (item.categoryId === "3" || item.categoryId === "4" || item.categoryId === "1") {
            totalBudget += item.budget;
        }
    }


    let budgetArray = [0, 0, 0];
    for (let item of obj) {
        if (item.categoryId === "3") {
            budgetArray[0] = parseFloat(((item.budget / totalBudget) * 100).toFixed(1));
        } else if (item.categoryId === "4") {
            budgetArray[1] = parseFloat(((item.budget / totalBudget) * 100).toFixed(1));
        } else if (item.categoryId === "1") {
            budgetArray[2] = parseFloat(((item.budget / totalBudget) * 100).toFixed(1));
        }
    }
  
    return budgetArray
}




const PieChartFunding = ({planBudgets}) => {

  
    const memberPlan = planBudgets.planBudgets
    const budgetArr = budgetArray(memberPlan);

    return (
        <div className="rounded-sm border border-stroke bg-white py-3 px-7.5 shadow-md dark:border-strokedark dark:bg-boxdark">
        <div className="mb-3 justify-between gap-4 sm:flex ml-4">
            <div >
            <h5 className="text-xl font-normal text-slate-600 dark:text-white">
                Core Funds Analytics
            </h5>
            </div>
            
        </div>

        <div className="mb-2">
            <div id="chartThree" className="mx-auto flex justify-center">
            <ReactApexChart
                options={options}
                series={budgetArr}
                type="donut"
            />
            </div>
        </div>

        
        </div>
  )
}

export default PieChartFunding
