import React from "react";
import { FaBeer } from "react-icons/fa";
import { useAuth } from "../../../context/AuthContext";

const StatCard = ({ title, funds, Icon, color, textColor }) => {
  const { userData } = useAuth();

  return (
    <div className="rounded-sm border border-stroke bg-white py-3 px-7.5 shadow-md dark:border-strokedark dark:bg-boxdark">
      {/* flex h-11.5 w-11.5 items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4 */}
      <div className="flex h-12 w-12 items-center justify-center rounded-full  ">
        <Icon size={30} color={color} />
      </div>

      <div className="mt-1 flex items-end justify-between">
        <div className="ml-10">
          <h4
            style={{ color: textColor }}
            className={`text-4xl font-bold dark:text-white`}
          >
            {userData.position !== "tl"
              ? "$" + funds.toLocaleString()
              : (funds / 65.47).toLocaleString()}
          </h4>
          <span className="text-l font-bold text-slate-700">{title}</span>
        </div>
      </div>
    </div>
  );
};

StatCard.defaultProps = {
  color: "#7281e9",
  Icon: FaBeer,
  funds: 0,
  textColor: "#64748b", // Default prop for Icon is FaBeer
};

export default StatCard;
