import React, { useState } from "react";
import DateRange from "../dateRange/DateRange";
import InputFields from "./InputFields";

const DsSales = () => {
  const communityTemplet = (services) => {
    const community = {
      name: "",
    };

    // Dynamically add properties based on the services array
    services.forEach(({ name, field }) => {
      community[field] = {
        hours: 0,
        sales: 0,
      };
    });

    return community;
  };

  const services = [
    {
      name: "Community Access",
      price: 65.47,
      field: "communityAccess",
    },
    {
      name: "Community Access Evening",
      price: 65.47,
      field: "communityAccessEvening",
    },
    {
      name: "Community Access Saturday",
      price: 65.47,
      field: "communityAccessSaturday",
    },
  ];

  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });
  const [numOfCommunity, setNumOfCommunity] = useState();
  const community = communityTemplet(services);
  const [communities, setCommunities] = useState([community]);

  const handleValueChange = (newValue) => {
    console.log("newValue:", newValue);
    setDate(newValue);
  };

  //community sales state management
  const addingValueState = (field, value, index) => {
    const sales = [...communities];
    const singleService = services.find((service) => service.field === field);
    sales[index][singleService.field].hours = value;
    sales[index][singleService.field].sales = (
      value * singleService.price
    ).toFixed(2);
    setCommunities(sales);
  };

  const handleChangeInput = (index, e) => {
    const { name, value } = e.target;

    addingValueState(name, value, index);
  };

  return (
    <>
      <DateRange
        value={date}
        handleValueChange={handleValueChange}
        numOfCommunity={numOfCommunity}
        setNumOfCommunity={setNumOfCommunity}
      />
      <form>
        {communities.map((community, index) => (
          <InputFields
            key={index}
            community={community}
            handleChangeInput={handleChangeInput}
            index={index}
            services={services}
          />
        ))}
        <button type="submit">Send</button>
      </form>
    </>
  );
};

export default DsSales;
