import React from 'react'

const PopUpError = ({message}) => {

  

  return (
    <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative w-[20%] mx-auto mb-5" role="alert">
        <strong className="font-bold">Error: </strong>
        <span className="block sm:inline">{message}</span>
       
</div>
  )
}

export default PopUpError