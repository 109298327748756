import React, { useState } from "react";
import DefaultLayout from "../../layout/DefaultLayout";
import UserSettingCard from "../../components/card/settingsCard/UserSettingCard";
import AddUser from "../../components/forms/addUser/AddUser";

const Settings = () => {
  const [selectedComponent, setSelectedComponent] = useState(null);
  const renderComponent = () => {
    switch (selectedComponent) {
      case "addUser":
        return <AddUser setSelectedComponent={setSelectedComponent} />;
      case "showUsers":
        return <h1>This is going to be th user page</h1>;
      default:
        return null;
        break;
    }
  };
  return (
    <DefaultLayout>
      {selectedComponent === null && (
        <UserSettingCard setSelectedComponent={setSelectedComponent} />
      )}
      {renderComponent()}
    </DefaultLayout>
  );
};

export default Settings;
