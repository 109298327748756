import React from 'react'
import { getPlanDetails } from '../../../utils/planDuration';

const MemberInfoCard = ({memberInfo, planStart, planEnd}) => {

  const planDuration = getPlanDetails(planStart, planEnd); 

  console.log( planDuration);
  return (
    
    <div className="rounded-sm border border-stroke bg-white py-3 px-7.5 shadow-md dark:border-strokedark dark:bg-boxdark">
        <dl className="-my-3 divide-y divide-gray-100 text-sm">
          <div className="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Name</dt>
            <dd className="text-gray-700 sm:col-span-2">{memberInfo.name}</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">NDIS</dt>
            <dd className="text-gray-700 sm:col-span-2">{memberInfo.ndis}</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Community</dt>
            <dd className={` rounded-md bg-opacity-10 py-1 px-3 text-sm font-medium w-fit h-fit  ${
            memberInfo.community === 'A' ? 'bg-green-400 text-green-600 border-2 border-green-800' : memberInfo.community === 'B'? 'bg-cyan-400 text-cyan-600': memberInfo.community === 'C'? 'bg-violet-400 text-violet-600' :'bg-pink-400 text-pink-600'
            }`}>{memberInfo.community}</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Plan Start</dt>
            <dd className="text-gray-700 sm:col-span-2">{planDuration.formattedStartDate}</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Plan End</dt>
            <dd className="text-gray-700 sm:col-span-2">{planDuration.formattedEndDate}</dd>
          </div>

          <div className="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
            <dt className="font-medium text-gray-900">Remaining Duration</dt>
            <dd className="text-gray-700 sm:col-span-2">{planDuration.remainingDurationInMonths} Months Left</dd>
          </div>
        </dl>
      </div>    

    
  )
}

export default MemberInfoCard